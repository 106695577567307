// NOTE: AVOID PUTTING ANYTHING IN THIS FILE. USE CSS-IN-JS INLINE STYLES INSTEAD.
html {
  line-height: 1.4;
}

h2 {
  margin: 0;
}

form {
  /* A bit of a hack, but this allows last child's `margin-bottom` to not collapse */
  padding-bottom: 1px;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
body,
html,
#root,
#root > .fabric {
  height: 100%;
}
